<template>
<div>
  <b-row class="profile-icons-svg">
    <b-col lg="4">
      <div class="iq-card ">
        <div class="iq-card-body pb-1">
          <div class="d-flex align-items-center justify-content-between">
            <p class="font-size-26 p-0 m-0">{{ $t('userProfile.events') }}</p>
            <svg xmlns="http://www.w3.org/2000/svg" width="25.135" height="25.135" viewBox="0 0 30.135 30.135">
              <path id="doctor-bag" d="M29.786,9.7H26.438V8.022A5.022,5.022,0,0,0,21.416,3h-6.7A5.022,5.022,0,0,0,9.7,8.022V9.7H6.348A3.358,3.358,0,0,0,3,13.045V29.787a3.358,3.358,0,0,0,3.348,3.348H29.786a3.358,3.358,0,0,0,3.348-3.348V13.045A3.358,3.358,0,0,0,29.786,9.7ZM13.045,8.022a1.674,1.674,0,0,1,1.674-1.674h6.7A1.674,1.674,0,0,1,23.09,8.022V9.7H13.045ZM23.09,23.09H19.741v3.348H16.393V23.09H13.045V19.742h3.348V16.393h3.348v3.348H23.09Z" transform="translate(-3 -3)" />
            </svg>
          </div>
          <p class="font-size-30 text-end m-0 pt-4">36</p>
        </div>
      </div>
    </b-col>
    <b-col lg="4">
      <div class="iq-card ">
        <div class="iq-card-body pb-1">
          <div class="d-flex align-items-center justify-content-between">
            <p class="font-size-26 p-0 m-0">{{ $t('userProfile.orders') }}</p>
            <svg xmlns="http://www.w3.org/2000/svg" width="25.135" height="25.135" viewBox="0 0 30.135 30.135">
              <path id="doctor-bag" d="M29.786,9.7H26.438V8.022A5.022,5.022,0,0,0,21.416,3h-6.7A5.022,5.022,0,0,0,9.7,8.022V9.7H6.348A3.358,3.358,0,0,0,3,13.045V29.787a3.358,3.358,0,0,0,3.348,3.348H29.786a3.358,3.358,0,0,0,3.348-3.348V13.045A3.358,3.358,0,0,0,29.786,9.7ZM13.045,8.022a1.674,1.674,0,0,1,1.674-1.674h6.7A1.674,1.674,0,0,1,23.09,8.022V9.7H13.045ZM23.09,23.09H19.741v3.348H16.393V23.09H13.045V19.742h3.348V16.393h3.348v3.348H23.09Z" transform="translate(-3 -3)" />
            </svg>
          </div>
          <p class="font-size-30 text-end m-0 pt-4">36</p>
        </div>
      </div>
    </b-col>
    <b-col lg="4">
      <div class="iq-card ">
        <div class="iq-card-body pb-1">
          <div class="d-flex align-items-center justify-content-between">
            <p class="font-size-26 p-0 m-0">{{ $t('userProfile.favourite') }}</p>
            <svg xmlns="http://www.w3.org/2000/svg" width="25.135" height="25.135" viewBox="0 0 30.135 30.135">
              <path id="doctor-bag" d="M29.786,9.7H26.438V8.022A5.022,5.022,0,0,0,21.416,3h-6.7A5.022,5.022,0,0,0,9.7,8.022V9.7H6.348A3.358,3.358,0,0,0,3,13.045V29.787a3.358,3.358,0,0,0,3.348,3.348H29.786a3.358,3.358,0,0,0,3.348-3.348V13.045A3.358,3.358,0,0,0,29.786,9.7ZM13.045,8.022a1.674,1.674,0,0,1,1.674-1.674h6.7A1.674,1.674,0,0,1,23.09,8.022V9.7H13.045ZM23.09,23.09H19.741v3.348H16.393V23.09H13.045V19.742h3.348V16.393h3.348v3.348H23.09Z" transform="translate(-3 -3)" />
            </svg>
          </div>
          <p class="font-size-30 text-end m-0 pt-4">36</p>
        </div>
      </div>
    </b-col>
  </b-row>
  <b-row>
    <b-col lg="12" >
      <iq-card class="iq-card-block iq-card-stretch iq-card-height">
        <template v-slot:body>
          <AmChart element="home-chart-06" type="dashboard2" :height="300" />
        </template>
      </iq-card>
    </b-col>
  </b-row>
</div>
</template>

<script>
import AmChart from '../components/amChart'
export default {
  name: 'statistics',
  components: { AmChart }
}
</script>

<style>
.profile-icons-svg svg {
  fill: var(--iq-secondary-dark) !important;
}

</style>
